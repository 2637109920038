import {
  signUp,
  userVerify,
  regenerateVerificationCode,
  signIn,
  signOut,
  createFolder,
  getFolder,
  deleteFolder,
  deleteFile,
  getUserState,
  getOrderableUserInfo,
  updateUserState,
  createFile,
  getDownloadUri,
  issueBillingKey,
  createFirstMonthlyOrder,
  payOrder,
  getPromotion,
  getServiceFeature,
  getPromotionCampaign,
  publishPromotionCode,
} from "./ApiFns";
import { initGS, getGS, setGS } from "usegs";
import { CURRENT_FOLDER } from "./GlobalStateKeys";
import { isValidValue, getRunningZone } from "./utils";
import { flutterAddFolderHistory } from "./FlutterBridges";
import { INITIAL_STATES } from "./InitialStates";
import { GET_CURRENT_FOLDER, GET_FOLDER_TREE } from "./ServerStateToGs";

export async function SIGN_UP(data) {
  return signUp(data);
}

export async function VERIFY_USER_EMAIL(data) {
  return userVerify(data);
}

export async function REGENERATE_VERIFICATION_CODE(data) {
  return regenerateVerificationCode(data);
}

export async function SIGN_IN(data) {
  const signInResult = await signIn(data);
  let failMessage = "";
  if (signInResult.is_ok) {
    initGS(INITIAL_STATES);
    const res = await GET_USER_STATES();
    if (res.is_ok) {
      if (res.result.session_status === "LOGIN") {
        const cfRes = await GET_CURRENT_FOLDER();
        if (cfRes.is_ok) {
          return signInResult;
        } else {
          failMessage = "failed to get current folder";
        }
      } else {
        failMessage = "session status is not LOGIN";
      }
    }
  } else {
    failMessage = "failed to sign in";
  }
  await SIGN_OUT();
  return {
    is_ok: false,
    message: failMessage,
  };
}

export async function GET_ORDERABLE_USER_INFO() {
  return await getOrderableUserInfo();
}

export async function ISSUE_BILLING_KEY(data) {
  return await issueBillingKey(data);
}

export async function CREATE_FIRST_MONTHLY_ORDER(data) {
  return await createFirstMonthlyOrder(data);
}

export async function PAY_ORDER(data) {
  return await payOrder(data);
}

export async function SIGN_OUT(data) {
  const soRes = await signOut(data);
  if (!soRes.is_ok) {
    console.error("failed to sign out");
  }
  if (getRunningZone() === "LOCAL") {
    document.cookie = "csrftoken=;";
  } else {
    document.cookie =
      "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.cloudusb.io;";
  }
  initGS(INITIAL_STATES);
  return soRes;
}

export async function GET_USER_STATES() {
  return await getUserState();
}

export async function SET_CURRENT_FOLDER(folderId) {
  if (folderId === null || folderId === undefined) {
    console.log("aborting SET_CURRENT_FOLDER, folderId is null or undefined");
    return;
  }
  const res = await updateUserState({ current_folder_id: folderId });
  if (res.is_ok) {
    const folder_res = await getFolder(folderId);
    if (folder_res.is_ok) {
      setGS(CURRENT_FOLDER, folder_res.result);
    } else if (folderId === 0) {
      createFolder({ parent_id: 0 }).then((res) => {
        if (res.is_ok) {
          setGS(CURRENT_FOLDER, res.result);
        } else {
          console.error("failed to create root folder with folderId 0");
        }
      });
    }
  } else {
    console.error("failed to update user state. current_folder_id: ", folderId);
  }
}

export function CREATE_FOLDER(folderName) {
  const currentFolder = getGS(CURRENT_FOLDER);
  if (currentFolder.folder_id === 0) {
    console.log("Current folder-id is zero, cannot create folder");
    return;
  }
  createFolder({ parent_id: currentFolder.folder_id, name: folderName }).then(
    () => {
      getFolder(currentFolder.folder_id).then((folder_res) => {
        setGS(CURRENT_FOLDER, folder_res.result);
        GET_FOLDER_TREE();
      });
    }
  );
}

export async function DELETE_FOLDER(folderId) {
  return deleteFolder(folderId);
}

export async function DELETE_FILE(fileId) {
  return deleteFile(fileId);
}

export async function CREATE_FILE(file, currentFolder) {
  if (!file || !isValidValue(currentFolder, "folder_id")) {
    return null;
  }
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("parent_folder_id", currentFolder.folder_id);
  const res = await createFile(data);
  GET_CURRENT_FOLDER();
  return res;
}

export async function MAKE_DOWNLOAD_LINKS(selectedItems) {
  const downloadLinks = {};
  if (selectedItems === null || selectedItems === undefined) {
    return downloadLinks;
  }
  for (let s of selectedItems) {
    if (s.item_type !== "FILE") {
      continue;
    }
    const fileId = s.item_id;
    const downloadLinkObjResult = await getDownloadUri(fileId);
    if (downloadLinkObjResult.is_ok) {
      const downloadLinkObj = downloadLinkObjResult.result;
      if (isValidValue(downloadLinkObj, "download_uri")) {
        downloadLinks[fileId] = downloadLinkObj.download_uri;
      } else {
        console.log("Error, no download link for file: ", s);
      }
    }
  }
  return downloadLinks;
}

export async function GET_PROMOTION_CAMPAIGN(campaign_id) {
  return await getPromotionCampaign(campaign_id);
}

export async function PUBLISH_PROMOTION_CODE(data) {
  return await publishPromotionCode(data);
}

export async function GET_SERVICE_FEATURE(key) {
  const res = await getServiceFeature(key);
  if (res.is_ok) {
    return res.result;
  }
  return {
    key: key,
    status: "DISABLED",
  };
}

export function CALL_FLUTER_MOVE_TO_ANOTHER_FOLDER(folderId) {
  flutterAddFolderHistory(folderId);
}
