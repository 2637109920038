import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "./Header";
import {
  ACCOUNT_SETTINGS_TITLE,
  ACCOUNT_PAYMENT_METHOD,
  ACCOUNT_REGISTER_NEW_PAYMENT_METHOD,
  ACCOUNT_CHANGE_PASSWORD,
  ACCOUNT_CURRENT_PASSWORD,
  ACCOUNT_NEW_PASSWORD,
  ACCOUNT_CONFIRM_NEW_PASSWORD,
  ACCOUNT_SAVE_PASSWORD,
  ACCOUNT_PERSONAL_INFORMATION,
  ACCOUNT_TIME_ZONE,
  ACCOUNT_PHONE_NUMBER,
  ACCOUNT_SAVE_INFORMATION,
  ACCOUNT_SELECT_LANGUAGE,
} from "../core/string_table/AccountStrings";
import { COMMON_SAVE } from "../core/string_table/CommonStrings";
import {
  ACCOUNT_PAGE,
  getString,
  getCommonString,
  KOREAN,
  ENGLISH,
} from "../core/StringTable";
import { USER } from "../core/GlobalStateKeys";
import { useGS } from "usegs";
import { GET_USER } from "../core/ServerStateToGs";
import { UPDATE_USER } from "../core/GsToServerState";

const getAccountString = (key) => getString(ACCOUNT_PAGE, key);

const timeZones = ["America/New_York", "America/Los_Angeles", "Asia/Seoul"];

export default function Account() {
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useGS(USER);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    GET_USER();
  }, []);

  useEffect(() => {
    if (user.language !== language) {
      setLanguage(user.language);
    }
  }, [user, setLanguage]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleSavePaymentMethod = () => {
    // Logic to save payment method
    handleClosePaymentDialog();
  };

  const handleSavePassword = () => {
    UPDATE_USER({
      old_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmNewPassword,
    });
  };

  const handleUserInfo = () => {
    UPDATE_USER({
      time_zone: user.time_zone,
      mobile_number: user.mobile_number,
    });
  };

  const handleSaveLanguage = () => {
    UPDATE_USER({
      language: user ? language : "",
    }).then((_) => {
      GET_USER();
    });
  };

  return (
    <>
      <Header />
      <Container>
        <br />
        <Typography variant="h4" component="h1" gutterBottom>
          {getAccountString(ACCOUNT_SETTINGS_TITLE)}
        </Typography>

        {/* Payment Method */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getAccountString(ACCOUNT_PAYMENT_METHOD)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenPaymentDialog}
              >
                {getAccountString(ACCOUNT_REGISTER_NEW_PAYMENT_METHOD)}
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Password Management */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getAccountString(ACCOUNT_CHANGE_PASSWORD)}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="current-password">
                      {getAccountString(ACCOUNT_CURRENT_PASSWORD)}
                    </InputLabel>
                    <Input
                      id="current-password"
                      type={showPassword ? "text" : "password"}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="new-password">
                      {getAccountString(ACCOUNT_NEW_PASSWORD)}
                    </InputLabel>
                    <Input
                      id="new-password"
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="confirm-new-password">
                      {getAccountString(ACCOUNT_CONFIRM_NEW_PASSWORD)}
                    </InputLabel>
                    <Input
                      id="confirm-new-password"
                      type={showPassword ? "text" : "password"}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSavePassword}
                >
                  {getAccountString(ACCOUNT_SAVE_PASSWORD)}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* User Information */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getAccountString(ACCOUNT_PERSONAL_INFORMATION)}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="timezone-select-label">
                      {getAccountString(ACCOUNT_TIME_ZONE)}
                    </InputLabel>
                    <Select
                      labelId="timezone-select-label"
                      value={user.time_zone}
                      onChange={(e) => {
                        user.time_zone = e.target.value;
                        setUser(user);
                      }}
                      label={getAccountString(ACCOUNT_TIME_ZONE)}
                    >
                      {timeZones.map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          {zone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={getAccountString(ACCOUNT_PHONE_NUMBER)}
                    value={user.mobile_number}
                    onChange={(e) => {
                      user.mobile_number = e.target.value;
                      setUser(user);
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUserInfo}
                >
                  {getAccountString(ACCOUNT_SAVE_INFORMATION)}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Language Selection */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getAccountString(ACCOUNT_SELECT_LANGUAGE)}
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="language-select-label">
                  {getAccountString(ACCOUNT_SELECT_LANGUAGE)}
                </InputLabel>
                <Select
                  labelId="language-select-label"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  label={getAccountString(ACCOUNT_SELECT_LANGUAGE)}
                >
                  <MenuItem value={KOREAN}>한국어</MenuItem>
                  <MenuItem value={ENGLISH}>English</MenuItem>
                  {/* Add more languages as needed */}
                </Select>
              </FormControl>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleSaveLanguage()}
                >
                  {getCommonString(COMMON_SAVE)}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Payment Method Dialog */}
        <Dialog open={openPaymentDialog} onClose={handleClosePaymentDialog}>
          <DialogTitle>Register New Payment Method</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your new payment method details below.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Credit Card Number"
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              label="Expiration Date"
              type="text"
              fullWidth
            />
            <TextField margin="dense" label="CVV" type="text" fullWidth />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePaymentDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSavePaymentMethod} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
