import * as React from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { SIGN_UP, GET_SERVICE_FEATURE } from "../core/AppEventHandlers";
import { COMMON_CLOSE, COMMON_MOVE } from "../core/string_table/CommonStrings";
import {
  SIGN_UP_TITLE,
  SIGN_UP_GUIDE,
  SIGN_UP_FIRST_NAME,
  SIGN_UP_LAST_NAME,
  SIGN_UP_EMAIL,
  SIGN_UP_PASSWORD,
  SIGN_UP_PASSWORD_CONFIRM,
  SIGN_UP_MOBILE_NUMBER,
  SIGN_UP_INVITATION_CODE,
  SIGN_UP_RECEIVE_SERVICE_UPDATES,
  SIGN_UP_MOVE_TO_SIGN_IN_PAGE,
  SIGN_UP_FAILED,
  SIGN_UP_FAIL_MESSAGE,
  SIGN_UP_READY,
  SIGN_UP_READY_MESSAGE,
} from "../core/string_table/SignUpStrings";
import {
  KOREAN,
  SIGN_UP_PAGE,
  getString,
  getCommonString,
  getErrorString,
} from "../core/StringTable";
import { USER } from "../core/GlobalStateKeys";
import { useGS } from "usegs";
import MessagePopup from "../components/MessagePopup";
import { extractErrorCode } from "../core/utils";

const getSignUpString = (key) => getString(SIGN_UP_PAGE, key);
const SIGN_UP_RESULT_SUCCESS = "SUCCESS";
const SIGN_UP_RESULT_FAIL = "FAIL";
const SIGN_UP_RESULT_NONE = "NONE";

export default function SignUp() {
  const navigate = useNavigate();
  const [invitationCode, setInvitationCode] = React.useState(false);
  const [signUpResult, setSignUpResult] = React.useState(SIGN_UP_RESULT_NONE);
  const [messageData, setMessageData] = React.useState({
    title: "",
    message: "",
  });
  const [verificationKey, setVerificationKey] = React.useState("");
  const [user] = useGS(USER);

  const handlePopupClose = (
    toEmailVerification = false,
    verificationKey = ""
  ) => {
    setSignUpResult(SIGN_UP_RESULT_NONE);
    if (toEmailVerification) {
      navigate(`/email-verification/${verificationKey}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const signup_data = {
      first_name: data.get("firstName"),
      last_name: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
      password_confirm: data.get("passwordConfirm"),
      mobile_number: data.get("mobileNumber"),
      invitation_code: data.get("invitationCode"),
    };
    SIGN_UP(signup_data).then((res) => {
      if (res.is_ok) {
        console.log(res.result);
        setVerificationKey(res.result.verification_key);
        setMessageData({
          title: getSignUpString(SIGN_UP_READY),
          message: getSignUpString(SIGN_UP_READY_MESSAGE),
        });
        setSignUpResult(SIGN_UP_RESULT_SUCCESS);
      } else {
        let message = getSignUpString(SIGN_UP_FAIL_MESSAGE);
        const errorCode = extractErrorCode(res.message);
        message += getErrorString(errorCode);
        setMessageData({
          title: getSignUpString(SIGN_UP_FAILED),
          message: message,
        });
        setSignUpResult(SIGN_UP_RESULT_FAIL);
      }
    });
  };

  React.useEffect(() => {
    GET_SERVICE_FEATURE("invitation-code").then((res) => {
      if (res.status === "ENABLED") {
        setInvitationCode(true);
      } else {
        setInvitationCode(false);
      }
    });
  }, [setInvitationCode]);

  return (
    <>
      <MessagePopup
        open={signUpResult === SIGN_UP_RESULT_SUCCESS}
        title={messageData.title}
        message={messageData.message}
        buttonText={getCommonString(COMMON_MOVE)}
        handleClose={() => handlePopupClose(true, verificationKey)}
      />
      <MessagePopup
        open={signUpResult === SIGN_UP_RESULT_FAIL}
        title={messageData.title}
        message={messageData.message}
        buttonText={getCommonString(COMMON_CLOSE)}
        handleClose={() => handlePopupClose(false, verificationKey)}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <SubscriptionsOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {getSignUpString(SIGN_UP_TITLE)}
          </Typography>
          <Typography component="h4" variant="h7">
            {getSignUpString(SIGN_UP_GUIDE)}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {user.language === KOREAN && (
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label={getSignUpString(SIGN_UP_LAST_NAME)}
                    name="lastName"
                    autoComplete="family-name"
                  />
                )}
                {user.language !== KOREAN && (
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label={getSignUpString(SIGN_UP_FIRST_NAME)}
                    autoFocus
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {user.language === KOREAN && (
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label={getSignUpString(SIGN_UP_FIRST_NAME)}
                    autoFocus
                  />
                )}
                {user.language !== KOREAN && (
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label={getSignUpString(SIGN_UP_LAST_NAME)}
                    name="lastName"
                    autoComplete="family-name"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={getSignUpString(SIGN_UP_EMAIL)}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={getSignUpString(SIGN_UP_PASSWORD)}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordConfirm"
                  label={getSignUpString(SIGN_UP_PASSWORD_CONFIRM)}
                  type="password"
                  id="passwordConfirm"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="mobileNumber"
                  label={getSignUpString(SIGN_UP_MOBILE_NUMBER)}
                  id="mobileNumber"
                  autoComplete="mobile-number"
                />
              </Grid>
              {invitationCode && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="invitationCode"
                    label={getSignUpString(SIGN_UP_INVITATION_CODE)}
                    id="invitationCode"
                    autoComplete="invitation-code"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label={getSignUpString(SIGN_UP_RECEIVE_SERVICE_UPDATES)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {getSignUpString(SIGN_UP_TITLE)}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  {getSignUpString(SIGN_UP_MOVE_TO_SIGN_IN_PAGE)}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
